// ✅ ملف التسجيل المعدّل (Signup.js) بكود المسؤول والتعديلات

import React, { useState } from "react";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { sendEmailVerification } from "firebase/auth";
import { toast } from "react-toastify";


const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [role, setRole] = useState("");
  const [adminCode, setAdminCode] = useState("");
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();
    const auth = getAuth();

    if (!role) {
      toast.role("✅ الرجاء اختيار الدور.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
await sendEmailVerification(user);

      // التحقق من كود المسؤول لجميع الأدوار المطلوبة
      if (["admin", "restaurant", "driver"].includes(role)) {
        if (adminCode !== process.env.REACT_APP_ADMIN_CODE) {
          await auth.currentUser.delete();
          toast.error("❌كود المسئول غير صحيح");
          return;
        }
      }

      // إنشاء بيانات المستخدم في Firestore
      const userData = {
        name,
        email,
        role,
        balance: 0,
        createdAt: new Date()
      };
      
      if (role === "driver") {
        userData.status = "متاح";
      } else if (role === "restaurant") {
        userData.status = "نشط";
      }
      

      const collectionName = role === "admin" ? "admins" : role === "restaurant" ? "restaurants" : "drivers";
      await setDoc(doc(db, collectionName, user.uid), userData);

      toast.success("✅ تم التسجيل بنجاح! تم إرسال رسالة تفعيل إلى بريدك.");
      navigate("/login");
    } catch (error) {
      console.error("❌ فشل التسجيل: " + error.message);
      toast.error("❌ فشل التسجيل: " + error.message);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: '#f0f2f5' }}>
      <div style={{ width: '400px', padding: '30px', backgroundColor: 'white', borderRadius: '10px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>إنشاء حساب جديد</h2>

        {/* اختيار الدور */}
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
          {['admin', 'restaurant', 'driver'].map((roleOption) => (
            <button
              key={roleOption}
              onClick={() => setRole(roleOption)}
              style={{
                flex: 1,
                padding: '10px',
                margin: '0 5px',
                backgroundColor: role === roleOption ? '#007bff' : '#f8f9fa',
                color: role === roleOption ? 'white' : 'black',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
            >
              {roleOption === 'admin' ? 'مسؤول' : roleOption === 'restaurant' ? 'مطعم' : 'سائق'}
            </button>
          ))}
        </div>

        <form onSubmit={handleSignup}>
          <input type="text" placeholder="الاسم" value={name} onChange={(e) => setName(e.target.value)} required style={inputStyle} />
          <input type="email" placeholder="البريد الإلكتروني" value={email} onChange={(e) => setEmail(e.target.value)} required style={inputStyle} />
          <input type="password" placeholder="كلمة المرور" value={password} onChange={(e) => setPassword(e.target.value)} required style={inputStyle} />

          {/* حقل كود المسؤول لكل الأدوار */}
          {['admin', 'restaurant', 'driver'].includes(role) && (
            <input type="password" placeholder="كود المسؤول" value={adminCode} onChange={(e) => setAdminCode(e.target.value)} required style={inputStyle} />
          )}

          <button type="submit" style={buttonStyle}>✅ تسجيل</button>
        </form>
      </div>
    </div>
  );
};

const inputStyle = {
  width: '100%',
  padding: '10px',
  marginBottom: '15px',
  border: '1px solid #ddd',
  borderRadius: '5px'
};

const buttonStyle = {
  width: '100%',
  padding: '10px',
  backgroundColor: '#28a745',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer'
};

export default Signup;
