// src/restaurant/pages/NewOrder.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  collection, query, where, getDocs, addDoc, updateDoc, doc,
  serverTimestamp, getDoc, GeoPoint
} from 'firebase/firestore';
import { db } from '../../firebase';
import { GoogleMap, Marker, DirectionsRenderer } from '@react-google-maps/api';
import { useGoogleMaps } from '../../components/GoogleMapsProvider';
import RestaurantSidebar from '../../components/RestaurantSidebar';
import RestaurantHeader from '../../components/RestaurantHeader';
import { getValidLocation, isValidCoordinate } from '../../utils/locationUtils'; // تأكد من استيراد isValidCoordinate

import './NewOrder.css';
import GooglePlacesAutocomplete from '../../components/GooglePlacesAutocomplete';
import { getMessaging, onMessage } from 'firebase/messaging';

const NewOrder = () => {
  const { isLoaded, loadError } = useGoogleMaps(); // استخراج loadError أيضًا
  const [drivers, setDrivers] = useState([]);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [deliveryInfo, setDeliveryInfo] = useState({
    customerName: '',
    phone: '',
    address: '',
    notes: '',
    amount: '',
    paymentMethod: 'نقدي',
    customerLocation: null // إضافة customerLocation إلى الحالة
  });
  const [restaurantData, setRestaurantData] = useState(null);
  const [, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [directions, setDirections] = useState(null);
  const [distance, setDistance] = useState(0);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const navigate = useNavigate();
  const restaurantId = localStorage.getItem('restaurantId');

  const isValidDriverLocation = (driver) => {
    return driver && 
           driver.location && 
           typeof driver.location.lat === 'number' && 
           typeof driver.location.lng === 'number' &&
           !isNaN(driver.location.lat) &&
           !isNaN(driver.location.lng);
  };
  // أضف سجلات تصحيح لمراقبة حالة تحميل خرائط Google
  useEffect(() => {
    console.log("حالة تحميل خرائط Google:", isLoaded);
    console.log("خطأ تحميل خرائط Google:", loadError);
    if (loadError) {
      setError(`خطأ في تحميل الخريطة: ${loadError.message || "خطأ غير معروف"}`);
    }
  }, [isLoaded, loadError]);

  useEffect(() => {
    if (drivers.length > 0) {
      console.log("بيانات مواقع السائقين:");
      drivers.forEach(driver => {
        console.log(`السائق ${driver.name}:`, driver.location);
        if (!isValidDriverLocation(driver)) {
          console.error(`موقع غير صالح للسائق ${driver.name}:`, driver.location);
        }
      });
    }
  }, [drivers]);

  useEffect(() => {
    if (!restaurantId) {
      navigate('/restaurant/login');
      return;
    }

    const fetchData = async () => {
      try {
        console.log("جاري جلب بيانات المطعم والسائقين...");
        const restaurantDoc = await getDoc(doc(db, 'restaurants', restaurantId));
        if (!restaurantDoc.exists()) {
          console.error("لم يتم العثور على بيانات المطعم");
          navigate('/restaurant/login');
          return;
        }

        const restaurantInfo = restaurantDoc.data();
        console.log("تم استلام بيانات المطعم:", restaurantInfo);
        
        if (!restaurantInfo.location || !restaurantInfo.location.lat || !restaurantInfo.location.lng) {
          console.log("موقع المطعم غير محدد، سيتم محاولة الحصول عليه تلقائيًا");
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
              const lat = position.coords.latitude;
              const lng = position.coords.longitude;
              console.log("تم الحصول على الموقع الحالي:", lat, lng);

              const restaurantRef = doc(db, 'restaurants', restaurantId);
              await updateDoc(restaurantRef, { location: { lat, lng } });

              setRestaurantData({ id: restaurantDoc.id, ...restaurantInfo, location: { lat, lng } });
            }, (error) => {
              console.error("فشل في تحديد الموقع:", error);
              alert("لم نتمكن من تحديد موقع المطعم تلقائيًا. يرجى التواصل مع الدعم.");
            });
          } else {
            alert("المتصفح لا يدعم تحديد الموقع الجغرافي.");
          }
        } else {
          console.log("موقع المطعم:", restaurantInfo.location);
          setRestaurantData({ id: restaurantDoc.id, ...restaurantInfo });
        }

        console.log("جاري البحث عن السائقين المتاحين...");
        const driversRef = collection(db, 'drivers');
        const q = query(driversRef, where('status', '==', 'متاح'));
        const querySnapshot = await getDocs(q);
        const availableDrivers = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        console.log("تم العثور على", availableDrivers.length, "سائق متاح");
        setDrivers(availableDrivers);
      } catch (error) {
        console.error('خطأ في جلب البيانات:', error);
        setError('حدث خطأ في جلب البيانات. يرجى المحاولة مرة أخرى.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchData();
  }, [navigate, restaurantId, setLoading]);
  
  const handleAddressSelect = (selectedAddress) => {
    console.log("تم اختيار عنوان:", selectedAddress);
    
    setDeliveryInfo({
      ...deliveryInfo,
      address: selectedAddress.address,
      customerLocation: selectedAddress.location // تخزين الموقع في الحالة
    });

    if (restaurantData && restaurantData.location) {
      console.log("حساب المسار من المطعم إلى العميل...");
      
      // التحقق من وجود خرائط Google
      if (!window.google || !window.google.maps) {
        console.error("مكتبة خرائط Google غير محملة بعد!");
        setError("لم يتم تحميل خرائط Google بشكل صحيح. يرجى تحديث الصفحة.");
        return;
      }
      
      // طلب الاتجاهات
      try {
        const directionsService = new window.google.maps.DirectionsService();
        const origin = new window.google.maps.LatLng(
          restaurantData.location.lat,
          restaurantData.location.lng
        );
        const destination = new window.google.maps.LatLng(
          selectedAddress.location.lat,
          selectedAddress.location.lng
        );

        directionsService.route(
          {
            origin,
            destination,
            travelMode: window.google.maps.TravelMode.DRIVING
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              console.log("تم الحصول على الاتجاهات بنجاح");
              setDirections(result);
              const distanceInMeters = result.routes[0].legs[0].distance.value;
              const distanceInKm = distanceInMeters / 1000;
              console.log("المسافة:", distanceInKm, "كم");
              setDistance(distanceInKm);
              calculateDeliveryFee(distanceInKm);
            } else {
              console.error("فشل في الحصول على الاتجاهات:", status);
              setError(`لم نتمكن من حساب المسار: ${status}`);
            }
          }
        );
      } catch (err) {
        console.error("خطأ في طلب الاتجاهات:", err);
        setError("حدث خطأ أثناء محاولة حساب المسار. يرجى المحاولة مرة أخرى.");
      }
    } else {
      console.error("موقع المطعم غير متوفر!");
      setError("لم يتم تحديد موقع المطعم. يرجى التواصل مع الدعم.");
    }
  };

  const calculateDeliveryFee = (distanceKm) => {
    let fee = 0;
    if (distanceKm <= 20) {
      fee = 1.350;
    } else {
      fee = 1.350 + (distanceKm - 20) * 0.100;
    }
    setDeliveryFee(parseFloat(fee.toFixed(3)));
    console.log("رسوم التوصيل:", fee.toFixed(3), "د.ك");
  };

  // Modified handleSubmit function to prevent multiple notifications
  useEffect(() => {
    const messaging = getMessaging();  // الحصول على الكائن الخاص بـ Firebase Cloud Messaging

    // تعريف onMessage listener لاستقبال الرسائل
    onMessage(messaging, (payload) => {
      console.log('تم استلام الإشعار:', payload);
      // يمكنك تنفيذ الإجراءات هنا مثل عرض إشعار أو تحديث الواجهة
      alert('تم استلام طلب جديد!');
    });
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    console.log("بدء معالجة إرسال الطلب...");
  
    // منع الضغط المتكرر على الزر
    if (submitting) {
      console.log("جاري المعالجة بالفعل، تم منع طلب متكرر");
      return;
    }
    
    // إنشاء رقم فريد للطلب لمنع التكرار
    const requestId = `order_${Date.now()}_${Math.random().toString(36).substring(2, 15)}`;
    console.log("رقم الطلب المؤقت للتتبع:", requestId);
  
    // التحقق الأولي من البيانات
    if (!restaurantId || !selectedDriver) {
      setError(!restaurantId ? 'خطأ: لم يتم العثور على معرف المطعم' : 'يرجى اختيار سائق للتوصيل');
      return;
    }
  
    const requiredFields = ['customerName', 'phone', 'address', 'amount'];
    const missingFields = requiredFields.filter(field => !deliveryInfo[field]?.trim());
    if (missingFields.length > 0) {
      setError(`يرجى ملء الحقول التالية: ${missingFields.join(', ')}`);
      return;
    }
  
    const amount = parseFloat(deliveryInfo.amount);
    if (isNaN(amount) || amount <= 0) {
      setError('قيمة الطلب يجب أن تكون رقمًا موجبًا');
      return;
    }
  
    if (!restaurantData || restaurantData.balance < deliveryFee) {
      setError(`رصيد المطعم غير كافٍ. الرصيد الحالي: ${restaurantData?.balance || 0} د.ك، المطلوب: ${deliveryFee} د.ك`);
      return;
    }
  
    // تعطيل الزر فورًا
    setSubmitting(true);
    setError('');
  
    // إضافة تأخير إضافي لمنع الطلبات المتكررة
    const waitBeforeProcessing = () => new Promise(resolve => setTimeout(resolve, 200));
    await waitBeforeProcessing();
  
    try {
      // جلب موقع المستخدم الحالي قبل إرسال الطلب
      const getCurrentLocation = () => {
        return new Promise((resolve, reject) => {
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                resolve({
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                  accuracy: position.coords.accuracy
                });
              },
              (error) => {
                console.error("فشل في تحديد الموقع:", error);
                resolve(null);
              },
              { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
            );
          } else {
            resolve(null);
          }
        });
      };
  
      const senderLocation = await getCurrentLocation();
      const ordersRef = collection(db, 'orders');
  
      // التحقق من وجود طلب قيد المعالجة لنفس العميل ورقم الهاتف
      const recentOrdersQuery = query(
        collection(db, 'orders'), 
        where('phone', '==', deliveryInfo.phone.trim()),
        where('customerName', '==', deliveryInfo.customerName.trim()),
        where('createdAt', '>', new Date(Date.now() - 60000)) // طلبات خلال آخر دقيقة
      );
      
      const recentOrdersSnapshot = await getDocs(recentOrdersQuery);
      if (!recentOrdersSnapshot.empty) {
        console.log("تم اكتشاف طلب متكرر محتمل!");
        const existingOrderId = recentOrdersSnapshot.docs[0].id;
        setError(`يبدو أنه تم إرسال طلب مماثل مؤخرًا (رقم ${existingOrderId}). الرجاء الانتظار قليلًا قبل إرسال طلب جديد.`);
        setSubmitting(false);
        return;
      }
  
      // التأكد من وجود إحداثيات صالحة للمطعم
      const restaurantLocation = getValidLocation(restaurantData?.location);
      console.log("موقع المطعم المستخدم:", restaurantLocation);
      
      // محاولة الحصول على إحداثيات العميل من مصادر مختلفة
      let customerLocation = null;
      
      // أولاً تحقق مما إذا كان لدينا إحداثيات Mapbox مخزنة في الحالة
      if (deliveryInfo.customerLocation && isValidCoordinate(deliveryInfo.customerLocation)) {
        customerLocation = deliveryInfo.customerLocation;
        console.log("استخدام إحداثيات من Mapbox:", customerLocation);
      } 
      // ثم جرب استخدام Google Directions API إذا كان متاحًا
      else if (directions?.routes[0]?.legs[0]?.end_location) {
        customerLocation = {
          lat: directions.routes[0].legs[0].end_location.lat(),
          lng: directions.routes[0].legs[0].end_location.lng()
        };
        console.log("استخدام إحداثيات من Google Directions:", customerLocation);
      }
      
      // إذا كنا ما زلنا لا نملك إحداثيات صالحة، أخبر المستخدم
      if (!customerLocation || !isValidCoordinate(customerLocation)) {
        console.error("لم يتم العثور على إحداثيات صالحة للعميل");
        setError('لم يتم تحديد موقع العميل بشكل صحيح. يرجى إعادة إدخال العنوان أو تحديده بشكل دقيق على الخريطة.');
        setSubmitting(false);
        return;
      }
  
      console.log("جاري إنشاء بيانات الطلب...");
      const orderData = {
        restaurantId,
        restaurantName: restaurantData?.name || 'مطعم غير معروف',
        restaurantLocation: new GeoPoint(restaurantLocation.lat, restaurantLocation.lng),
        driverId: selectedDriver.id,
        driverName: selectedDriver.name,
        customerName: deliveryInfo.customerName.trim(),
        phone: deliveryInfo.phone.trim(),
        address: deliveryInfo.address.trim(),
        notes: deliveryInfo.notes?.trim() || '',
        amount,
        paymentMethod: deliveryInfo.paymentMethod || 'نقدي',
        distance: distance || 0,
        deliveryFee: deliveryFee || 0,
        status: 'قيد التنفيذ 🚀',
        createdAt: serverTimestamp(),
        customerLocation: new GeoPoint(customerLocation.lat, customerLocation.lng),
        senderLocation: senderLocation 
          ? new GeoPoint(senderLocation.lat, senderLocation.lng) 
          : null,
        senderLocationAccuracy: senderLocation?.accuracy || null,
        requestId: requestId, // إضافة رقم الطلب المؤقت لمنع التكرار
        notificationSent: false // تتبع حالة الإشعار
      };
  
      console.log("جاري حفظ الطلب في قاعدة البيانات...");
      const orderRef = await addDoc(ordersRef, orderData);
      console.log("تم إنشاء الطلب بنجاح برقم:", orderRef.id);
  
      // تحديث رصيد المطعم
      console.log("جاري تحديث رصيد المطعم...");
      await updateDoc(doc(db, 'restaurants', restaurantId), {
        balance: (restaurantData.balance || 0) - deliveryFee
      });
      
      // تسجيل المعاملة المالية
      console.log("جاري تسجيل المعاملة المالية...");
      await addDoc(collection(db, 'transactions'), {
        restaurantId,
        type: 'خصم',
        amount: deliveryFee,
        description: `رسوم توصيل للطلب #${orderRef.id}`,
        timestamp: serverTimestamp(),
        orderId: orderRef.id // ربط المعاملة بالطلب للتتبع
      });
  
      // --------- الحل الأساسي لمشكلة الإشعارات المتكررة ----------
      // 1. التحقق هل تم إرسال إشعار لهذا الطلب من قبل
      const checkForExistingNotifications = async () => {
        try {
          const notificationsRef = collection(db, 'notifications');
          const q = query(notificationsRef, where('orderId', '==', orderRef.id));
          const snapshot = await getDocs(q);
          return !snapshot.empty;
        } catch (error) {
          console.error("خطأ في التحقق من الإشعارات السابقة:", error);
          return false;
        }
      };
  
      const notificationExists = await checkForExistingNotifications();
      if (notificationExists) {
        console.log("تم اكتشاف إشعار سابق لهذا الطلب. تم تخطي إرسال إشعار جديد.");
      } else {
        console.log("جاري إرسال إشعار للسائق...");
        
        try {
          // إضافة سجل الإشعار أولاً لمنع الإرسال المتكرر
          await addDoc(collection(db, 'notifications'), {
            orderId: orderRef.id,
            driverId: selectedDriver.id,
            timestamp: serverTimestamp(),
            status: 'pending'
          });
          
          // إرسال الإشعار الفعلي
          const response = await fetch("https://sendordernotification-f6l6kr6lva-uc.a.run.app", {
            method: "POST",
            headers: { 
              "Content-Type": "application/json",
              "X-Request-ID": requestId, // إضافة رقم الطلب المؤقت لمنع التكرار
              "X-Order-ID": orderRef.id
            },
            body: JSON.stringify({
              fcmToken: selectedDriver.fcmToken,
              title: "طلب جديد",
              body: `طلب جديد من ${restaurantData.name} - ${orderRef.id}`,
              orderId: orderRef.id,
              requestId: requestId, // إضافة لمنع التكرار
              restaurantLocation: {
                lat: restaurantLocation.lat,
                lng: restaurantLocation.lng
              },
              customerLocation: {
                lat: customerLocation.lat,
                lng: customerLocation.lng
              },
              navigationType: "COORDINATES",
              restaurantName: restaurantData?.name || 'مطعم غير معروف',
              customerAddress: deliveryInfo.address.trim()
            })
          });
          
          const responseText = await response.text();
          console.log("استجابة خدمة الإشعارات:", responseText);
          
          // تحديث حالة الإشعار في Firestore
          await updateDoc(doc(db, 'orders', orderRef.id), {
            notificationSent: true,
            notificationTimestamp: serverTimestamp()
          });
          
        } catch (notificationError) {
          console.error("خطأ في إرسال الإشعار:", notificationError);
        }
      }
      // ----------------------------------------------------------
  
      setSuccess(`تم إرسال الطلب بنجاح! رقم الطلب: ${orderRef.id}`);
      
      // إعادة تعيين النموذج
      setDeliveryInfo({ 
        customerName: '', 
        phone: '', 
        address: '', 
        notes: '', 
        amount: '', 
        paymentMethod: 'نقدي',
        customerLocation: null 
      });
      setSelectedDriver(null);
      setDirections(null);
      setDistance(0);
      setDeliveryFee(0);
  
      console.log("اكتمل إنشاء الطلب بنجاح، سيتم التوجيه إلى صفحة تفاصيل الطلب...");
      setTimeout(() => navigate(`/restaurant/orders/${orderRef.id}`), 3000);
    } catch (error) {
      console.error('خطأ مفصل في إرسال الطلب:', error);
      setError('حدث خطأ غير متوقع. يرجى المحاولة مرة أخرى.');
    } finally {
      setSubmitting(false);
    }
  };

  // ابحث عن هذه الوظيفة في الملف واستبدلها بالكامل
  const renderDriverMarkers = () => {
    return drivers
      .filter(isValidDriverLocation)
      .map(driver => (
        <Marker
          key={driver.id}
          position={{
            lat: Number(driver.location.lat),
            lng: Number(driver.location.lng)
          }}
          icon={{
            url: '/images/car-icon.png', // مسار الأيقونة
            scaledSize: new window.google.maps.Size(40, 40), // حجم الأيقونة
          }}
          onClick={() => setSelectedDriver(driver)}
          title={driver.name}
        />
      ));
  };

  return (
    <div className="restaurant-new-order-page">
      <RestaurantSidebar />
      <div className="restaurant-new-order-content">
        <RestaurantHeader restaurantName={restaurantData?.name || 'المطعم'} />
        <div className="new-order-container">
          <h1 className="page-title">إرسال طلب جديد</h1>
          {error && <div className="error-message">{error}</div>}
          {success && <div className="success-message">{success}</div>}

          <div className="new-order-grid">
            <div className="new-order-form-container">
              <form onSubmit={handleSubmit} className="new-order-form">
                <div className="form-section">
                  <h2>بيانات العميل</h2>

                  <div className="form-group">
                    <label htmlFor="customerName">اسم العميل *</label>
                    <input
                      type="text"
                      id="customerName"
                      value={deliveryInfo.customerName}
                      onChange={(e) => setDeliveryInfo({...deliveryInfo, customerName: e.target.value})}
                      disabled={submitting}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="phone">رقم الهاتف *</label>
                    <input
                      type="tel"
                      id="phone"
                      value={deliveryInfo.phone}
                      onChange={(e) => setDeliveryInfo({...deliveryInfo, phone: e.target.value})}
                      disabled={submitting}
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="address">العنوان *</label>
                    <GooglePlacesAutocomplete onPlaceSelected={handleAddressSelect} />

                  </div>

                  <div className="form-group">
                    <label htmlFor="notes">ملاحظات</label>
                    <textarea
                      id="notes"
                      value={deliveryInfo.notes}
                      onChange={(e) => setDeliveryInfo({...deliveryInfo, notes: e.target.value})}
                      disabled={submitting}
                    />
                  </div>
                </div>

                <div className="form-section">
                  <h2>تفاصيل الطلب</h2>

                  <div className="form-group">
                    <label htmlFor="amount">قيمة الطلب (د.ك) *</label>
                    <input
                      type="number"
                      id="amount"
                      value={deliveryInfo.amount}
                      onChange={(e) => setDeliveryInfo({...deliveryInfo, amount: e.target.value})}
                      disabled={submitting}
                      step="0.001"
                      min="0"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="paymentMethod">طريقة الدفع *</label>
                    <select
                      id="paymentMethod"
                      value={deliveryInfo.paymentMethod}
                      onChange={(e) => setDeliveryInfo({...deliveryInfo, paymentMethod: e.target.value})}
                      disabled={submitting}
                      required
                    >
                      <option value="نقدي">نقدي</option>
                      <option value="كي نت">كي نت</option>
                      <option value="بطاقة ائتمان">بطاقة ائتمان</option>
                    </select>
                  </div>

                  {distance > 0 && (
                    <div className="delivery-info">
                      <div className="info-item">
                        <span className="info-label">المسافة:</span>
                        <span className="info-value">{distance.toFixed(2)} كم</span>
                      </div>
                      <div className="info-item">
                        <span className="info-label">رسوم التوصيل:</span>
                        <span className="info-value">{deliveryFee.toFixed(3)} د.ك</span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="form-section">
                  <h2>اختر السائق</h2>
                  {drivers.length === 0 ? (
                    <p className="no-drivers">لا يوجد سائقين متاحين حالياً</p>
                  ) : (
                    <div className="drivers-list">
                      {drivers.map(driver => (
                        <div 
                          key={driver.id} 
                          className={`driver-card ${selectedDriver?.id === driver.id ? 'selected' : ''}`}
                          onClick={() => setSelectedDriver(driver)}
                        >
                          <div className="driver-avatar">{driver.name.charAt(0)}</div>
                          <div className="driver-info">
                            <h3>{driver.name}</h3>
                            <p>{driver.phone}</p>
                          </div>
                          <div className="driver-status">متاح</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="form-actions">
                  <button 
                    type="submit" 
                    className="submit-btn" 
                    disabled={submitting || drivers.length === 0}
                  >
                    {submitting ? 'جاري الإرسال...' : 'إرسال الطلب'}
                  </button>
                </div>
              </form>
            </div>

            <div className="new-order-form-container" style={{ marginBottom: '15px' }}>
              <div className="form-section">
                <h2>تعليمات استخدام الخريطة</h2>
                <ul style={{ listStyleType: 'disc', paddingRight: '20px' }}>
                  <li>يمكنك النقر على أي مكان في الخريطة لتحديد موقع العميل بدقة</li>
                  <li>يمكنك إدخال الإحداثيات مباشرة في حقل العنوان بالصيغة: 29.3759, 47.9774</li>
                  <li>تأكد من ظهور علامة "العميل" على الخريطة قبل إرسال الطلب</li>
                </ul>
              </div>
            </div>

            <div className="map-container">
              {loadError && (
                <div className="map-error" style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f8d7da', color: '#721c24' }}>
                  حدث خطأ في تحميل الخريطة: {loadError.message || "خطأ غير معروف"}
                  <button 
                    onClick={() => window.location.reload()} 
                    style={{ marginTop: '10px', padding: '5px 10px', backgroundColor: '#4a90e2', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}
                  >
                    إعادة المحاولة
                  </button>
                </div>
              )}
              {!isLoaded && !loadError && (
                <div className="map-loading">جاري تحميل الخريطة...</div>
              )}
              {isLoaded && !loadError && (
  <GoogleMap
    mapContainerStyle={{ width: '100%', height: '100%' }}
    center={
      restaurantData?.location && 
      typeof restaurantData.location.lat === 'number' && 
      typeof restaurantData.location.lng === 'number' &&
      !isNaN(restaurantData.location.lat) &&
      !isNaN(restaurantData.location.lng)
        ? { 
            lat: Number(restaurantData.location.lat), 
            lng: Number(restaurantData.location.lng) 
          }
        : { lat: 29.3759, lng: 47.9774 } // مركز الكويت الافتراضي
    }
    zoom={12}
    options={{
      streetViewControl: false,
      fullscreenControl: false
    }}
    onClick={(e) => {
                    // إضافة القدرة على النقر على الخريطة لتحديد موقع العميل
                    const customerLocation = {
                      lat: e.latLng.lat(),
                      lng: e.latLng.lng()
                    };
                    
                    if (window.confirm('هل تريد استخدام هذا الموقع كعنوان العميل؟')) {
                      // تحديث موقع العميل
                      setDeliveryInfo({
                        ...deliveryInfo,
                        customerLocation: customerLocation,
                        address: `موقع محدد يدويًا: ${customerLocation.lat.toFixed(6)}, ${customerLocation.lng.toFixed(6)}`
                      });
                      
                      // تحديث الاتجاهات إذا كان موقع المطعم متاحًا
                      if (restaurantData?.location) {
                        const directionsService = new window.google.maps.DirectionsService();
                        directionsService.route(
                          {
                            origin: new window.google.maps.LatLng(
                              restaurantData.location.lat,
                              restaurantData.location.lng
                            ),
                            destination: new window.google.maps.LatLng(
                              customerLocation.lat,
                              customerLocation.lng
                            ),
                            travelMode: window.google.maps.TravelMode.DRIVING
                          },
                          (result, status) => {
                            if (status === window.google.maps.DirectionsStatus.OK) {
                              setDirections(result);
                              const distanceInMeters = result.routes[0].legs[0].distance.value;
                              const distanceInKm = distanceInMeters / 1000;
                              setDistance(distanceInKm);
                              calculateDeliveryFee(distanceInKm);
                            }
                          }
                        );
                      }
                    }
                  }}
                >
                  {restaurantData?.location && 
 typeof restaurantData.location.lat === 'number' && 
 typeof restaurantData.location.lng === 'number' &&
 !isNaN(restaurantData.location.lat) &&
 !isNaN(restaurantData.location.lng) && (
  <Marker
    position={{
      lat: Number(restaurantData.location.lat),
      lng: Number(restaurantData.location.lng)
    }}
    icon={{
      url: '/images/restaurant-marker.png',
      scaledSize: new window.google.maps.Size(40, 40)
                      }}
                    />
                  )}

                  {renderDriverMarkers()}
                  
                  {deliveryInfo.customerLocation && 
 isValidCoordinate(deliveryInfo.customerLocation) && (
  <Marker
    position={{
      lat: Number(deliveryInfo.customerLocation.lat),
      lng: Number(deliveryInfo.customerLocation.lng)
    }}
    icon={{
      url: '/images/customer-marker.png',
      scaledSize: new window.google.maps.Size(40, 40)
    }}
    label={{
      text: 'العميل',
      color: '#ffffff',
      fontSize: '12px',
      fontWeight: 'bold'
                      }}
                    />
                  )}

                  {directions && (
                    <DirectionsRenderer
                      directions={directions}
                      options={{
                        suppressMarkers: true,
                        polylineOptions: {
                          strokeColor: '#4a90e2',
                          strokeWeight: 5
                        }
                      }}
                    />
                  )}
                </GoogleMap>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrder;