import { getToken, onMessage } from "firebase/messaging";
import { messaging } from "../firebase";
import { collection, addDoc, serverTimestamp, updateDoc, doc } from "firebase/firestore";
import { db,} from "../firebase";

// تسجيل Service Worker للإشعارات
export const registerServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      console.log('Service worker registration successful with scope:', registration.scope);
      return registration;
    } catch (error) {
      console.error('Service worker registration failed:', error);
      return null;
    }
  } else {
    console.warn('Service workers are not supported by this browser');
    return null;
  }
};

// طلب إذن الإشعارات من المستخدم
export const requestNotificationPermission = async () => {
  if (!messaging) {
    console.warn('⚠️ Firebase Messaging غير متاح - تأكد من أنك على localhost أو HTTPS');
    return null;
  }

  // محاولة تسجيل Service Worker إذا لم يكن مسجلاً بالفعل
  await registerServiceWorker();

  try {
    console.log('🔹 طلب إذن الإشعارات...');
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      console.log('✅ تم منح إذن الإشعارات');
      return getFCMToken();
    } else {
      console.warn('❌ تم رفض إذن الإشعارات');
      return null;
    }
  } catch (error) {
    console.error('❌ خطأ في طلب إذن الإشعارات:', error);
    return null;
  }
};

// الحصول على رمز FCM من Firebase
export const getFCMToken = async () => {
  if (!messaging) return null;

  try {
    const currentToken = await getToken(messaging, {
      vapidKey: 'BJh7mFmi44Du5fvsaayT-niXBM4SNrfrG3o8jl3Bf8g7TnUPzg4f3tuxBDfIWHv5zOudwfpi2a5axmL52TBWppw'
    });

    if (currentToken) {
      console.log('✅ تم الحصول على رمز FCM');
      return currentToken;
    } else {
      console.warn('⚠️ لم يتم الحصول على رمز FCM');
      return null;
    }
  } catch (error) {
    console.error('❌ خطأ في جلب رمز FCM:', error);
    return null;
  }
};

// الاستماع إلى الإشعارات الواردة عند عمل التطبيق
export const listenForMessages = () => {
  if (!messaging) return;

  onMessage(messaging, (payload) => {
    console.log('🔔 تم استلام إشعار:', payload);

    if (payload.notification) {
      const { title, body } = payload.notification;

      try {
        new Notification(title, {
          body,
          icon: "/logo192.png"
        });
      } catch (err) {
        console.error('❌ خطأ في عرض الإشعار:', err);
      }
    }
  });
};

// إرسال إشعار إلى السائق من Cloud Functions
export const sendNotification = async (recipientType, recipientId, message, fcmToken = null, orderId = null) => {
  try {
    // إنشاء معرف فريد للطلب لمنع التكرار
    const requestId = `notify_${Date.now()}_${Math.random().toString(36).substring(2, 15)}`;
    
    // تحضير بيانات الرسالة
    const messageContent = typeof message === 'string' 
        ? { title: "طلب جديد", body: message } 
        : message;

    // 1. حفظ الإشعار في قاعدة البيانات Firestore
    const notificationRef = await addDoc(collection(db, "notifications"), {
      recipientType,
      recipientId,
      title: messageContent.title || "طلب جديد",
      message: messageContent.body || message,
      isRead: false,
      status: 'pending',
      orderId,
      requestId,
      timestamp: serverTimestamp(),
    });
    console.log('✅ تم حفظ الإشعار في قاعدة البيانات بمعرف:', notificationRef.id);

    // 2. إرسال إشعار FCM عبر دالة HTTPS
    if (fcmToken) {
      try {
        const response = await fetch("https://sendordernotification-f6l6kr6lva-uc.a.run.app", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-Request-ID": requestId,
            "X-Notification-ID": notificationRef.id
          },
          body: JSON.stringify({
            fcmToken,
            title: messageContent.title || "طلب جديد",
            body: messageContent.body || message,
            orderId: orderId || "",
            notificationId: notificationRef.id,
            requestId
          })
        });

        // معالجة الاستجابة بطريقة آمنة
        const responseText = await response.text();
        let result;
        try {
          result = JSON.parse(responseText);
        } catch (e) {
          result = { rawResponse: responseText };
        }
        
        console.log("✅ نتيجة الإشعار من الدالة السحابية:", result);
        
        // تحديث حالة الإشعار في قاعدة البيانات
        await updateDoc(doc(db, "notifications", notificationRef.id), {
          status: 'sent',
          deliveryResponse: result,
          deliveryTimestamp: serverTimestamp()
        });
      } catch (fcmError) {
        console.error("❌ خطأ في إرسال إشعار FCM:", fcmError);
        
        // تسجيل الخطأ في قاعدة البيانات
        await updateDoc(doc(db, "notifications", notificationRef.id), {
          status: 'failed',
          error: fcmError.message,
          errorTimestamp: serverTimestamp()
        });
      }
    } else {
      // تحديث الإشعار لتوضيح أنه لم يتم إرساله لعدم وجود توكن
      await updateDoc(doc(db, "notifications", notificationRef.id), {
        status: 'no_token',
        updatedAt: serverTimestamp()
      });
    }

    return notificationRef.id;
  } catch (error) {
    console.error('❌ خطأ في إرسال الإشعار:', error);
    return null;
  }
};

// وضع علامة مقروء على الإشعار
export const markNotificationAsRead = async (notificationId) => {
  if (!notificationId) return false;
  
  try {
    await updateDoc(doc(db, "notifications", notificationId), {
      isRead: true,
      readAt: serverTimestamp()
    });
    console.log('✅ تم وضع علامة مقروء على الإشعار');
    return true;
  } catch (error) {
    console.error('❌ خطأ في وضع علامة مقروء على الإشعار:', error);
    return false;
  }
};

// التحقق من تسجيل Service Worker
export const checkServiceWorkerRegistration = async () => {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    const fcmWorkerRegistered = registrations.some(reg => 
      reg.active && reg.active.scriptURL.includes('firebase-messaging-sw.js')
    );
    
    console.log('Service worker registrations:', registrations.map(reg => reg.active?.scriptURL));
    console.log('Firebase messaging service worker registered:', fcmWorkerRegistered);
    
    return fcmWorkerRegistered;
  }
  
  return false;
};