import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getMessaging } from "firebase/messaging";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAfpEXpKaRnVTfOdRpZaHBhoGCGlsHbVE8",
  authDomain: "atdelivery-9d139.firebaseapp.com",
  projectId: "atdelivery-9d139",
  databaseURL: "https://atdelivery-9d139.firebaseio.com",
  storageBucket: "atdelivery-9d139.appspot.com",
  messagingSenderId: "76043371440",
  appId: "1:76043371440:web:d2a57531269b997892cc75"
};

// تهيئة Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);

// وظيفة مساعدة للتحقق مما إذا كان السياق آمنًا للإشعارات
const isNotificationContextSecure = () => {
  return typeof window !== 'undefined' && 
         typeof navigator !== 'undefined' &&
         'serviceWorker' in navigator &&
         (window.location.protocol === 'https:' || 
          window.location.hostname === 'localhost' || 
          window.location.hostname === '127.0.0.1');
};

// تهيئة Messaging مع معالجة أفضل للأخطاء
export let messaging = null;
if (isNotificationContextSecure()) {
  try {
    messaging = getMessaging(app);
    console.log('✅ تم تهيئة Firebase Messaging بنجاح');
  } catch (error) {
    console.error('❌ خطأ في تهيئة Firebase Messaging:', error);
    
    if (error.code === 'messaging/permission-blocked') {
      console.log('⚠️ تم حظر إذن الإشعارات من قبل المستخدم');
    } else if (error.code === 'messaging/unsupported-browser') {
      console.log('⚠️ هذا المتصفح لا يدعم إشعارات Firebase');
    }
  }
} else {
  console.log('ℹ️ تم تخطي تهيئة Firebase Messaging - السياق غير آمن أو مدعوم');
}

// وظيفة للتحقق من حالة تسجيل Service Worker
export const checkServiceWorkerRegistration = async () => {
  if (!('serviceWorker' in navigator)) {
    return { registered: false, error: 'المتصفح لا يدعم Service Worker' };
  }
  
  try {
    const registrations = await navigator.serviceWorker.getRegistrations();
    const fcmWorkerRegistered = registrations.some(reg => 
      reg.active && reg.active.scriptURL.includes('firebase-messaging-sw.js')
    );
    
    return { 
      registered: fcmWorkerRegistered,
      registrations: registrations.map(reg => reg.active?.scriptURL)
    };
  } catch (error) {
    console.error('خطأ في التحقق من Service Worker:', error);
    return { registered: false, error: error.message };
  }
};

export default app;