import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../firebase';
import { toast } from "react-toastify";


const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    if (!selectedRole) {
      setError("الرجاء اختيار الدور");
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (!user.emailVerified) {
        toast.warning("⚠️ يرجى تأكيد بريدك الإلكتروني أولاً قبل تسجيل الدخول.");
        return;
      }
      
      // التحقق من الدور
      let roleDoc;
      switch(selectedRole) {
        case 'admin':
          roleDoc = await getDoc(doc(db, 'admins', user.uid));
          if (roleDoc.exists()) {
            localStorage.setItem('userRole', 'admin');
            navigate('/dashboard');
          } else {
            toast.error("🚫 هذا الحساب غير مسجل كمسئول. الرجاء التواصل مع الإدارة.");

          }
          break;
        
        case 'restaurant':
          roleDoc = await getDoc(doc(db, 'restaurants', user.uid));
          if (roleDoc.exists()) {
            localStorage.setItem('userRole', 'restaurant');
            localStorage.setItem('restaurantId', user.uid);
            navigate('/restaurant/dashboard');
          } else {
            toast.error("🚫 هذا الحساب غير مسجل كمطعم. الرجاء التواصل مع الإدارة.");
          }
          break;
        
        case 'driver':
          roleDoc = await getDoc(doc(db, 'drivers', user.uid));
          if (roleDoc.exists()) {
            localStorage.setItem('userRole', 'driver');
            navigate('/driver/dashboard');
          } else {
            toast.error("🚫 هذا الحساب غير مسجل كسائق. الرجاء التواصل مع الإدارة.");
          }
          break;
        default:
          toast.error("🚫 برجاء اختيار دورك الصحيح او مراجعة الادارة");
        }
    } catch (error) {
      console.error("❌ Error code:", error.code);
      console.error("❌ Error message:", error.message);
      toast.error("❌ خطأ في تسجيل الدخول. تحقق من البريد وكلمة المرور.");
    }
  };

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh', 
      backgroundColor: '#f0f2f5' 
    }}>
      <div style={{
        width: '400px',
        padding: '30px',
        backgroundColor: 'white',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
      }}>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>تسجيل الدخول</h2>
        
        {/* اختيار الدور */}
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          marginBottom: '20px' 
        }}>
          {['admin', 'restaurant', 'driver'].map((role) => (
            <button
              key={role}
              onClick={() => setSelectedRole(role)}
              style={{
                flex: 1,
                padding: '10px',
                margin: '0 5px',
                backgroundColor: selectedRole === role ? '#007bff' : '#f8f9fa',
                color: selectedRole === role ? 'white' : 'black',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer'
              }}
            >
              {role === 'admin' ? 'مسؤول' : 
               role === 'restaurant' ? 'مطعم' : 
               'سائق'}
            </button>
          ))}
        </div>

        <form onSubmit={handleLogin}>
          {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}

          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="البريد الإلكتروني"
            required 
            style={{ 
              width: '100%', 
              padding: '10px', 
              marginBottom: '15px',
              border: '1px solid #ddd',
              borderRadius: '5px'
            }}
          />

          <input 
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            placeholder="كلمة المرور"
            required 
            style={{ 
              width: '100%', 
              padding: '10px', 
              marginBottom: '15px',
              border: '1px solid #ddd',
              borderRadius: '5px'
            }}
          />

          <button 
            type="submit" 
            style={{
              width: '100%',
              padding: '10px',
              backgroundColor: '#28a745',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            تسجيل الدخول
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;